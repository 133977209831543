import React, { useState, ErrorInfo } from 'react';
import IOTServicesCalculator from './components/IOTServicesCalculator';
import AWSServicesCalculator from './components/AWSServicesCalculator';
import AzureServicesCalculator from './components/AzureServicesCalculator';
import SelectedServicesTotalCost from './components/SelectedServicesTotalCost';
import { logError } from './utils/logger';

interface Service {
  id: string;
  name: string;
  price: number;
  unit: string;
  description: string;
  quantity: number;  // Added quantity field
}

class ErrorBoundary extends React.Component<{children: React.ReactNode}, {hasError: boolean}> {
  constructor(props: {children: React.ReactNode}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error);
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

function App() {
  const [iotTotal, setIotTotal] = useState(0);
  const [awsTotal, setAwsTotal] = useState(0);
  const [azureTotal, setAzureTotal] = useState(0);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleTotalChange = (service: 'iot' | 'aws' | 'azure', total: number, services: Service[]) => {
    try {
      switch (service) {
        case 'iot':
          setIotTotal(total);
          break;
        case 'aws':
          setAwsTotal(total);
          break;
        case 'azure':
          setAzureTotal(total);
          break;
      }
      setSelectedServices(prevServices => {
        const filteredServices = prevServices.filter(s => !services.some(newS => newS.id === s.id));
        return [...filteredServices, ...services];
      });
    } catch (error) {
      logError(error as Error);
      setError(`An error occurred while updating the ${service.toUpperCase()} total. Please try again.`);
    }
  };

  return (
    <ErrorBoundary>
      <div className="App bg-[#D5D2CA] min-h-screen">
        <header className="bg-[#002D5A] text-white p-6">
          <h1 className="text-3xl font-bold text-center">IOT Services Calculator</h1>
        </header>
        <main className="container mx-auto p-4 lg:p-8">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <strong className="font-bold">Error: </strong>
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <IOTServicesCalculator onTotalChange={(total, services) => handleTotalChange('iot', total, services)} />
            </div>
            <div>
              <AWSServicesCalculator onTotalChange={(total, services) => handleTotalChange('aws', total, services)} />
            </div>
            <div>
              <AzureServicesCalculator onTotalChange={(total, services) => handleTotalChange('azure', total, services)} />
            </div>
            <div>
              <SelectedServicesTotalCost 
                iotTotal={iotTotal}
                awsTotal={awsTotal}
                azureTotal={azureTotal}
                selectedServices={selectedServices}
              />
            </div>
          </div>
        </main>
      </div>
    </ErrorBoundary>
  );
}

export default App;
