import React, { useState, useEffect } from 'react';
import { Plus, Minus, Trash2 } from 'lucide-react';
import { logError } from '../utils/logger';

interface IOTService {
  id: string;
  name: string;
  price: number;
  unit: string;
  description: string;
  quantity: number;  // Added quantity field
}

interface IOTServicesCalculatorProps {
  onTotalChange: (total: number, services: IOTService[]) => void;
}

const IOTServicesCalculator: React.FC<IOTServicesCalculatorProps> = ({ onTotalChange }) => {
  const [services, setServices] = useState<IOTService[]>([]);
  const [selectedServices, setSelectedServices] = useState<IOTService[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [selectedService, setSelectedService] = useState<IOTService | null>(null);

  useEffect(() => {
    fetchIOTServices();
  }, []);

  useEffect(() => {
    try {
      const newTotalCost = selectedServices.reduce((sum, service) => sum + service.quantity * service.price, 0);
      setTotalCost(newTotalCost);
      onTotalChange(newTotalCost, selectedServices);
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while calculating the total cost. Please try again.');
    }
  }, [selectedServices, onTotalChange]);

  const fetchIOTServices = async () => {
    setLoading(true);
    setError(null);

    try {
      // Simulating an API call with setTimeout
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Sample data to simulate API response
      const sampleServices: IOTService[] = [
        { id: '1001', name: 'SEAT', price: 80.15, unit: 'Per Month', description: 'All direct labor, contracts, hardware, and other direct costs required by IOT to provide IT Service Delivery for the desktop and associated centralized services.' , quantity: 0 },
        { id: '1001L', name: 'Std. MS Office 365 User License', price: 29.00, unit: 'Per Month Per Named User', description: 'Standard Microsoft Office 365 User License' , quantity: 0 },
        { id: '1001LW', name: 'Web Productivity', price: 5.23, unit: 'Per Month Per Named User', description: 'Web-based productivity tools' , quantity: 0 },
        { id: '1001ES', name: 'Web Productivity Enhanced', price: 12.68, unit: 'Per Month Per Named User', description: 'Enhanced web-based productivity tools' , quantity: 0 },
        { id: '1001KL', name: 'Complete Web Productivity', price: 16.40, unit: 'Per Month Per Named User', description: 'Complete suite of web-based productivity tools' , quantity: 0 },
        { id: '1001LE', name: 'Web Productivity Enh. Email', price: 9.33, unit: 'Per Month Per Named User', description: 'Web productivity with enhanced email capabilities' , quantity: 0 },
        { id: '1001LI', name: 'Identity Access', price: 4.48, unit: 'Per Month Per Named User', description: 'Identity and access management services' , quantity: 0 },
        { id: '1014', name: 'Email-SharePoint G3', price: 17.22, unit: 'Per Month Per Named User', description: 'Email and SharePoint services (G3 license)' , quantity: 0 },
        { id: '1020', name: 'Remote Access (Citrix)', price: 17.83, unit: 'Per Month Per Named User', description: 'Remote access services using Citrix' , quantity: 0 },
        { id: '1043', name: 'Telephone - Centrex', price: 0, unit: 'Per Month', description: 'Pass Through telephone services' , quantity: 0 },
        { id: '1044', name: 'Telephone - Remote', price: 0, unit: 'Per Vendor Contract', description: 'Pass Through remote telephone services' , quantity: 0 },
        { id: '1049', name: 'Database Consulting Services', price: 100.00, unit: 'Per Hour', description: 'Consulting services for database management' , quantity: 0 },
        { id: '1050', name: 'Physical Server Hosting', price: 550.14, unit: 'Per Month Per Server', description: 'Hosting services for physical servers' , quantity: 0 },
        { id: '1052', name: 'Virtual Server Hosting - Base', price: 72.93, unit: 'Per Month', description: 'Base hosting services for virtual servers' , quantity: 0 },
        { id: '1052A', name: 'Virtual Server Hosting (+ CPU)', price: 5.98, unit: 'Per Month Per Additional CPU', description: 'Additional CPU for virtual server hosting' , quantity: 0 },
        { id: '1052B', name: 'Virtual Server Hosting (+ GB)', price: 3.21, unit: 'Per Month Per Additional 1 GB RAM', description: 'Additional RAM for virtual server hosting' , quantity: 0 },
        { id: '1053', name: 'Cloud Service Provider', price: 0, unit: 'As billed by the CSP', description: 'Pass Through cloud service provider costs' , quantity: 0 },
        { id: '1053A', name: 'Cloud Operations', price: 25, unit: '% of CSP cost Per Month', description: 'Cloud operations management' , quantity: 0 },
        { id: '1066', name: 'Jobs Scheduling', price: 0.8776, unit: 'Per Scheduled Job or Event', description: 'Scheduling services for jobs or events' , quantity: 0 },
        { id: '1112', name: 'Data Circuits - Off Network', price: 0, unit: 'Per Month Per Circuit', description: 'Pass Through off-network data circuit costs' , quantity: 0 },
        { id: '1114', name: 'Database Hosting', price: 21.85, unit: 'Per Database Per Month', description: 'Hosting services for databases' , quantity: 0 },
        { id: '1114A', name: 'Database Size Overage 1GB+', price: 1.09, unit: 'Per Month Per GB over 1GB', description: 'Additional storage for databases exceeding 1GB' , quantity: 0 },
        { id: '1114B', name: 'Exadata Hosting', price: 974.36, unit: 'Per Month Per Database', description: 'Hosting services for Exadata databases' , quantity: 0 },
        { id: '1117', name: 'Cellular Phone Service', price: 0, unit: 'Variable', description: 'Pass Through cellular phone service costs' , quantity: 0 },
        { id: '1120', name: 'GMIS HRMS Services', price: 0, unit: 'Monthly / Headcount', description: 'COMP Allocation for GMIS HRMS Services' , quantity: 0 },
        { id: '1121', name: 'GMIS Financials/Hyperion', price: 0, unit: 'Monthly / Transaction', description: 'COMP Allocation for GMIS Financials/Hyperion' , quantity: 0 },
        { id: '1126', name: 'TSO/DSO & OCRs', price: 0, unit: 'Materials at QPA vendor\'s price', description: 'Pass Through for TSO/DSO & OCRs' , quantity: 0 },
        { id: '1131', name: 'IN.Gov', price: 0, unit: 'Varies', description: 'Various IN.Gov services' , quantity: 0 },
        { id: '1137MP', name: 'DR Premium – Physical Server', price: 393.95, unit: 'Per Month Per Server', description: 'Disaster Recovery Premium for Physical Servers' , quantity: 0 },
        { id: '1137MV', name: 'DR Premium – Virtual Server', price: 140.52, unit: 'Per Month Per Server', description: 'Disaster Recovery Premium for Virtual Servers' , quantity: 0 },
        { id: '1137P', name: 'DR Traditional – Physical Server', price: 373.28, unit: 'Per Month Per Server', description: 'Disaster Recovery Traditional for Physical Servers' , quantity: 0 },
        { id: '1137R', name: 'Rack Hosting Service', price: 433.44, unit: 'Per Month Per Rack Space', description: 'Hosting service for rack space' , quantity: 0 },
        { id: '1137V', name: 'DR Traditional – Virtual Server', price: 171.40, unit: 'Per Month Per Server', description: 'Disaster Recovery Traditional for Virtual Servers' , quantity: 0 },
        { id: '1141', name: 'WAN Management Services', price: 105.05, unit: 'Per Month Per Circuit', description: 'Wide Area Network management services' , quantity: 0 },
        { id: '1153A', name: 'AGOL Level 1 (Viewer)', price: 10.00, unit: 'Per Month Per Named User', description: 'ArcGIS Online Level 1 Viewer license' , quantity: 0 },
        { id: '1153C', name: 'ArcGIS Consulting', price: 100.00, unit: 'Per Hour', description: 'Consulting services for ArcGIS' , quantity: 0 },
        { id: '1153D', name: 'ArcGIS Desktop', price: 36.00, unit: 'Per Month Per User', description: 'ArcGIS Desktop license' , quantity: 0 },
        { id: '1153O', name: 'ArcGIS Online Level 2 (Creator)', price: 19.00, unit: 'Per Month Per User', description: 'ArcGIS Online Level 2 Creator license' , quantity: 0 },
        { id: '1153P', name: 'Portal Level 2 (Creator)', price: 19.00, unit: 'Per Month Per Named User', description: 'Portal Level 2 Creator license' , quantity: 0 },
        { id: '1153S', name: 'ArcGIS Server Instance', price: 500.00, unit: 'Per Month', description: 'ArcGIS Server Instance' , quantity: 0 },
        { id: '1153T', name: 'GIS End-User Training', price: 0, unit: 'Varies', description: 'Pass Through GIS End-User Training' , quantity: 0 },
        { id: '1155', name: 'Network Access Services', price: 299.30, unit: 'Per Month Per Switch/Router/Firewall', description: 'Network access services for switches, routers, and firewalls' , quantity: 0 },
        { id: '1170', name: 'Appl. Development - Standard', price: 117.00, unit: 'Per Hour', description: 'Standard application development services' , quantity: 0 },
        { id: '1170J', name: 'Appl. Development - Junior', price: 80.00, unit: 'Per Hour', description: 'Junior application development services' , quantity: 0 },
        { id: '1170M', name: 'Appl. Development Maintenance', price: 1000.00, unit: 'Per Month', description: 'Application development maintenance services' , quantity: 0 },
        { id: '1170R', name: 'Appl. Development - Architect', price: 138.00, unit: 'Per Hour', description: 'Application development architect services' , quantity: 0 },
        { id: '1170S', name: 'Appl. Development - Senior', price: 129.00, unit: 'Per Hour', description: 'Senior application development services' , quantity: 0 },
        { id: '1170CRMA', name: 'CRM Administrator', price: 131.25, unit: 'Per Hour', description: 'CRM Administrator services' , quantity: 0 },
        { id: '1170CRMD', name: 'CRM Developer', price: 156.25, unit: 'Per Hour', description: 'CRM Developer services' , quantity: 0 },
        { id: '1170CRMR', name: 'CRM Architect', price: 187.50, unit: 'Per Hour', description: 'CRM Architect services' , quantity: 0 },
        { id: '1175', name: 'Security - Baseline', price: 52.89, unit: 'Per Month Per Server and SEAT', description: 'Baseline security services for servers and SEAT' , quantity: 0 },
        { id: '1177A', name: 'Shared SAN Storage', price: 0.3100, unit: 'Per Month Per GB', description: 'Shared SAN storage services' , quantity: 0 },
        { id: '1177F', name: 'VM Server Additional Storage', price: 0.27, unit: 'Per Month Per GB over 100 GB', description: 'Additional storage for VM servers' , quantity: 0 },
        { id: '1177K', name: 'Standard File Storage', price: 0.15, unit: 'Per Month Per GB', description: 'Standard file storage services' , quantity: 0 },
        { id: '1177R', name: 'Archive/Cold Storage', price: 0.03, unit: 'Per Month Per GB', description: 'Archive or cold storage services' , quantity: 0 },
        { id: '1177S', name: 'Secure File Storage', price: 0.17, unit: 'Per Month Per GB', description: 'Secure file storage services' , quantity: 0 },
        { id: '1178x', name: 'Adobe Products', price: 0, unit: 'Per Month Per User', description: 'Various Adobe product licenses' , quantity: 0 },
        { id: '1180', name: 'Security - Confidential', price: 207.69, unit: 'Per Month Per Server and Database', description: 'Enhanced security for confidential data' , quantity: 0 },
        { id: '1183', name: 'Cloud Contact Center', price: 31.10, unit: 'Per Month Per User', description: 'Cloud-based contact center services' , quantity: 0 },
        { id: '1183A', name: 'Cloud Contact Center Annual Commit', price: 0, unit: 'Per Contract', description: 'Annual commitment for cloud contact center' , quantity: 0 },
        { id: '1183M', name: 'Cloud Contact Center Monthly Usage', price: 0, unit: 'Per Contract', description: 'Monthly usage for cloud contact center' , quantity: 0 },
        { id: '1186', name: 'VaaS – IP Phone', price: 0, unit: 'Per Vendor Contract', description: 'Voice as a Service IP phone' , quantity: 0 },
        { id: '1186T', name: 'MSTV License', price: 5.78, unit: 'Per Month Per Account', description: 'Microsoft Teams Voice license' , quantity: 0 },
        { id: '1187', name: 'Data Circuits - On Network', price: 0, unit: 'Per Month Per Circuit', description: 'On-network data circuit costs' , quantity: 0 },
        { id: '1188', name: 'Telecom Management Services', price: 4.04, unit: 'Per Month Per Line', description: 'Telecom management services' , quantity: 0 },
        { id: '1189', name: 'Workstation Software Licenses', price: 0, unit: 'Varies', description: 'Various workstation software licenses' , quantity: 0 },
        { id: '1191', name: 'Oracle Application Hosting', price: 4021.31, unit: 'Per Month Per Site', description: 'Hosting services for Oracle applications' , quantity: 0 },
        { id: '1193', name: 'Dynamics CRM Online', price: 0, unit: 'MS CRM license + IOT fee', description: 'Microsoft Dynamics CRM Online services' , quantity: 0 },
        { id: '1193A', name: 'Dynamics 365 Accessories', price: 0, unit: 'Monthly / Service or Product', description: 'Additional Dynamics 365 services or products' , quantity: 0 },
        { id: '1193B', name: 'Service Admin Fee', price: 3.07, unit: 'Per License Per User', description: 'Administration fee for services' , quantity: 0 },
        { id: '1193L', name: 'Systems Admin', price: 115.00, unit: 'Per Hour', description: 'Systems administration services' , quantity: 0 },
        { id: '1195', name: 'Server Management', price: 139.81, unit: 'Per Month Per Server', description: 'Server management services' , quantity: 0 },
        { id: '1196', name: 'Oracle WebCenter Content', price: 1.64, unit: 'Per Month Per GB', description: 'Oracle WebCenter Content services' , quantity: 0 },
        { id: '1199PE', name: 'MS Project - Essentials', price: 5.53, unit: 'Per User Per Month', description: 'Microsoft Project Essentials license' , quantity: 0 },
        { id: '1199PR', name: 'MS Project Plan 5', price: 43.00, unit: 'Per User Per Month', description: 'Microsoft Project Plan 5 license' , quantity: 0 },
        { id: '1199PP', name: 'MS Project Plan 3', price: 26.00, unit: 'Per User Per Month', description: 'Microsoft Project Plan 3 license' , quantity: 0 },
        { id: '1199P1', name: 'MS Project Plan 1', price: 8.50, unit: 'Per User Per Month', description: 'Microsoft Project Plan 1 license' , quantity: 0 },
        { id: '1200F', name: 'IOT Rack Space - Floor', price: 300.00, unit: 'Per Month Per Rack', description: 'Rack space on the floor' , quantity: 0 },
        { id: '1200R', name: 'IOT Rack Space - Rack', price: 350.00, unit: 'Per Month Per Rack', description: 'Rack space in a provided rack' , quantity: 0 },
        { id: '1201', name: 'Contracted Long Distance', price: 0, unit: '$0.0000 - $0.0200 Per Minute', description: 'Contracted long distance phone services' , quantity: 0 },
        { id: '1202', name: '800 # Service - Long Distance', price: 0, unit: '$0.0080 - $0.0200 Per Minute', description: '800 number long distance services' , quantity: 0 },
        { id: '1203', name: 'Misc. Telecom Services', price: 0, unit: 'Per Minute', description: 'Pass Through miscellaneous telecom services, $0.0080 - $0.0200 Per Minute' , quantity: 0 },
        { id: '1204', name: 'HelpDesk', price: 0, unit: 'N/A', description: 'HelpDesk services included with SEAT' , quantity: 0 },
        { id: '1211', name: 'Microsoft Power BI', price: 0, unit: 'As billed by the CSP', description: 'Pass Through Microsoft Power BI services' , quantity: 0 },
        { id: '1212', name: 'Indiana Security as a Service (ISOS)', price: 5000.00, unit: 'Per Month Per Agency', description: 'Security as a Service for Indiana agencies' , quantity: 0 },
        { id: '1215', name: 'Compliance Center of Excellence', price: 100.00, unit: 'Per Hour', description: 'Compliance Center of Excellence services' , quantity: 0 },
        { id: '1219', name: 'MS Visio', price: 12.25, unit: 'Per Month Per Named User', description: 'Microsoft Visio license' , quantity: 0 },
        { id: '1220', name: 'PRM IV&V Services (Hourly)', price: 135.00, unit: 'Per Hour', description: 'Project Risk Management Independent Verification & Validation services (hourly price)' , quantity: 0 },
        { id: '1220F', name: 'PRM IV&V Services (Flat price)', price: 0, unit: 'Monthly Flat price', description: 'Project Risk Management Independent Verification & Validation services (flat price, varies monthly)' , quantity: 0 },
        { id: '1220M', name: 'IV&V Vendor Management', price: 1100.00, unit: 'Per Month Per Project', description: 'Independent Verification & Validation vendor management' , quantity: 0 },
        { id: '1220V', name: 'Vendor IV&V Services', price: 0, unit: 'Monthly price', description: 'Vendor-provided Independent Verification & Validation services (price varies monthly)' , quantity: 0 },
        { id: '1221B', name: 'WebEx Basic', price: 11.11, unit: 'Per Month Per Account', description: 'Basic WebEx account' , quantity: 0 },
        { id: '1221E', name: 'WebEx Enhanced', price: 45.00, unit: 'Per Month Per Account', description: 'Enhanced WebEx account' , quantity: 0 },
        { id: '1221V', name: 'MS Video', price: 12.45, unit: 'Per Month Per Account', description: 'Microsoft Video services' , quantity: 0 },
        { id: '1221Z', name: 'ZoomGov', price: 19.17, unit: 'Per Month Per Account', description: 'ZoomGov account' , quantity: 0 },
        { id: '1223', name: 'Server Maintenance (REMI)', price: 0, unit: 'Depends on Equipment', description: 'Server maintenance services, price varies depending on equipment' , quantity: 0 },
        { id: '1224B', name: 'Data Protection', price: 0.08, unit: 'Per Month Per GB', description: 'Data protection services' , quantity: 0 },
        { id: '1225', name: 'Access IN ID Verification', price: 500, unit: 'Minimum Per Month', description: 'Access Indiana ID verification services, shared cost model' , quantity: 0 },
        { id: '1226', name: 'Mulesoft', price: 0, unit: 'Enterprise Initiative', description: 'Mulesoft services provided at no charge as an enterprise initiative' , quantity: 0 },
        { id: '1227', name: 'GoAnywhere Products', price: 0, unit: 'Varies', description: 'Various GoAnywhere products and services, see 1227B, 1227C, 1227D, 1227E for details' , quantity: 0 },
        { id: '1227B', name: 'GoAnywhere Automated File Transfers - Domains', price: 2100.00, unit: 'Per Domain Per Year', description: 'GoAnywhere automated file transfer domain services' , quantity: 0 },
        { id: '1227C', name: 'GoAnywhere Automated File Transfers - Users', price: 3.30, unit: 'Per Named User Per Month', description: 'GoAnywhere automated file transfer user services' , quantity: 0 },
        { id: '1227D', name: 'GoAnywhere Automated File Transfers - Agents', price: 180.00, unit: 'Per Agent Per Year', description: 'GoAnywhere automated file transfer agent services' , quantity: 0 },
        { id: '1227E', name: 'GoAnywhere Automated File Transfers - Support', price: 0, unit: 'Varies', description: 'GoAnywhere automated file transfer support services, see 1227F and 1227G for details' , quantity: 0 },
        { id: '1227F', name: 'GoAnywhere Domain Support', price: 1050.00, unit: 'Per Domain Per Year', description: 'GoAnywhere domain support services' , quantity: 0 },
        { id: '1227G', name: 'GoAnywhere Agent Support', price: 90.00, unit: 'Per Agent Per Year', description: 'GoAnywhere agent support services' , quantity: 0 },
        { id: '1232', name: 'OpenText ECM Site', price: 589.73, unit: 'Per Month Per Site', description: 'OpenText Enterprise Content Management site services' , quantity: 0 },
        { id: '1232A', name: 'OpenText ECM Additional Users', price: 23.59, unit: 'Per Month Per User', description: 'Additional user licenses for OpenText Enterprise Content Management' , quantity: 0 },
        { id: '1232B', name: 'OpenText Intelligent Capture', price: 0.0622, unit: 'Per Page Imported', description: 'OpenText Intelligent Capture services' , quantity: 0 },
        { id: '1233D', name: 'ITSM Workflow Development', price: 75.00, unit: 'Per Hour for Complex Workflows', description: 'IT Service Management workflow development for complex workflows' , quantity: 0 },
        { id: 'Liaison', name: 'Agency Liaison Program', price: 0, unit: 'N/A', description: 'Agency Liaison Program provided at no charge' , quantity: 0 },
        { id: 'Work-Mgt', name: 'ITSM Work Management', price: 0, unit: 'N/A', description: 'IT Service Management work management provided at no charge' , quantity: 0 },
      ];
      setServices(sampleServices);
    } catch (error) {
      logError(error as Error);
      setError('Failed to fetch IOT services. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (id: string, change: number) => {
    try {
      setSelectedServices(prevServices =>
        prevServices.map(service =>
          service.id === id ? { ...service, quantity: Math.max(0, service.quantity + change) } : service
        )
      );
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while updating the service quantity. Please try again.');
    }
  };

  const addService = (service: IOTService) => {
    try {
      if (!selectedServices.some(s => s.id === service.id)) {
        setSelectedServices([...selectedServices, { ...service, quantity: 1 }]);
      }
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while adding the service. Please try again.');
    }
  };

  const removeService = (id: string) => {
    try {
      setSelectedServices(selectedServices.filter(service => service.id !== id));
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while removing the service. Please try again.');
    }
  };

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="p-4 bg-white rounded-lg shadow-md">Loading IOT services...</div>;
  }

  if (error) {
    return <div className="p-4 bg-white rounded-lg shadow-md text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col bg-[#D5D2CA]">
      <div className="bg-[#002D5A] text-white py-4 px-6 rounded-t-lg">
        <h1 className="text-2xl font-bold">IOT Services Cost Calculator</h1>
      </div>
      <div className="bg-white p-6 rounded-b-lg shadow-md">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Service list */}
          <div className="lg:w-1/2">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search for an IOT service..."
                className="w-full p-2 border rounded bg-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="mb-4 max-h-96 overflow-y-auto bg-gray-50 rounded shadow">
              {filteredServices.map(service => (
                <div key={service.id} className="flex justify-between items-center p-3 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                     onClick={() => addService(service)}
                     onMouseEnter={() => setSelectedService(service)}
                     onMouseLeave={() => setSelectedService(null)}>
                  <span>{service.name} (${service.price.toFixed(4)}/{service.unit})</span>
                  <span className="text-sm text-gray-500">{service.id}</span>
                </div>
              ))}
            </div>

            {selectedService && (
              <div className="mb-4 p-3 bg-gray-50 rounded shadow">
                <h3 className="font-bold">{selectedService.name}</h3>
                <p>{selectedService.description}</p>
              </div>
            )}
          </div>

          {/* Selected services and total cost */}
          <div className="lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Selected IOT Services</h2>
            <div className="space-y-3">
              {selectedServices.map((service) => (
                <div key={service.id} className="flex items-center justify-between p-3 bg-gray-50 rounded shadow">
                  <div>
                    <span className="font-bold">{service.name}</span>
                    <br />
                    <span className="text-sm text-gray-500">{service.id}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2">${service.price.toFixed(4)}/{service.unit}</span>
                    <button onClick={() => handleQuantityChange(service.id, -1)} className="p-1 bg-gray-200 rounded"><Minus size={16} /></button>
                    <span className="mx-2">{service.quantity}</span>
                    <button onClick={() => handleQuantityChange(service.id, 1)} className="p-1 bg-gray-200 rounded"><Plus size={16} /></button>
                    <button onClick={() => removeService(service.id)} className="ml-2 p-1 bg-gray-200 rounded"><Trash2 size={16} /></button>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 text-xl font-bold">
              Total Cost: ${totalCost.toFixed(2)}/month
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOTServicesCalculator;