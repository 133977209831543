import React, { useState, useEffect } from 'react';
import { Plus, Minus, Trash2 } from 'lucide-react';
import { logError } from '../utils/logger';

interface AWSService {
  id: string;
  name: string;
  price: number;
  unit: string;
  description: string;
  quantity: number;  // Added quantity field
}

interface AWSServicesCalculatorProps {
  onTotalChange: (total: number, services: AWSService[]) => void;
}

const AWSServicesCalculator: React.FC<AWSServicesCalculatorProps> = ({ onTotalChange }) => {
  const [services, setServices] = useState<AWSService[]>([]);
  const [selectedServices, setSelectedServices] = useState<AWSService[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [selectedService, setSelectedService] = useState<AWSService | null>(null);

  useEffect(() => {
    fetchAWSServices();
  }, []);

  useEffect(() => {
    try {
      // Calculate total cost with 25% markup (1.25 multiplier)
      const newTotalCost = selectedServices.reduce((sum, service) => sum + service.quantity * service.price * 1.25, 0);
      setTotalCost(newTotalCost);
      onTotalChange(newTotalCost, selectedServices);
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while calculating the total cost. Please try again.');
    }
  }, [selectedServices, onTotalChange]);

  const fetchAWSServices = async () => {
    setLoading(true);
    setError(null);

    try {
      // Simulating an API call with setTimeout
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Sample data to simulate API response
      const sampleServices: AWSService[] = [
        { id: '3001', name: 'Amazon EC2', price: 0.0116, unit: 'Per Hour', description: 'Scalable virtual servers in the cloud.', quantity: 0 },
        { id: '3002', name: 'Amazon S3', price: 0.023, unit: 'Per GB', description: 'Scalable object storage for data backup and archiving.', quantity: 0 },
        { id: '3003', name: 'Amazon RDS', price: 0.017, unit: 'Per Hour', description: 'Managed relational database service with scaling options.', quantity: 0 },
        { id: '3004', name: 'Amazon Lambda', price: 0.20, unit: 'Per 1 Million Requests', description: 'Run code without provisioning or managing servers.', quantity: 0 },
        { id: '3005', name: 'Amazon DynamoDB', price: 1.25, unit: 'Per WCU', description: 'Managed NoSQL database for fast and flexible performance.', quantity: 0 },
        { id: '3006', name: 'Amazon CloudFront', price: 0.085, unit: 'Per GB', description: 'Fast content delivery with low latency and high transfer speeds.', quantity: 0 },
        { id: '3007', name: 'Amazon Route 53', price: 0.50, unit: 'Per Hosted Zone', description: 'Scalable DNS and domain name registration.', quantity: 0 },
        { id: '3008', name: 'Amazon Elastic Load Balancing', price: 0.0225, unit: 'Per Hour', description: 'Automatically distribute incoming traffic across multiple targets.', quantity: 0 },
        { id: '3009', name: 'Amazon SNS', price: 0.50, unit: 'Per 1 Million Requests', description: 'Managed push notification service for mobile devices and distributed systems.', quantity: 0 },
        { id: '3010', name: 'Amazon SQS', price: 0.40, unit: 'Per Million Requests', description: 'Fully managed message queuing service.', quantity: 0 },
        { id: '3011', name: 'Amazon EBS', price: 0.10, unit: 'Per GB', description: 'Persistent block storage for use with EC2.', quantity: 0 },
        { id: '3012', name: 'Amazon VPC', price: 0.05, unit: 'Per Hour', description: 'Isolated cloud resources for security and compliance.', quantity: 0 },
        { id: '3013', name: 'AWS CloudTrail', price: 2.00, unit: 'Per Million Events', description: 'Track and log AWS account activity.', quantity: 0 },
        { id: '3014', name: 'Amazon CloudWatch', price: 0.30, unit: 'Per GB', description: 'Monitoring and management service for cloud applications.', quantity: 0 },
        { id: '3015', name: 'Amazon Redshift', price: 0.25, unit: 'Per Hour', description: 'Fully managed data warehouse for big data analytics.', quantity: 0 },
        { id: '3016', name: 'AWS Config', price: 0.003, unit: 'Per Resource', description: 'Service that enables you to assess, audit, and evaluate the configurations of AWS resources.', quantity: 0 },
        { id: '3017', name: 'Amazon Kinesis Data Streams', price: 0.015, unit: 'Per Shard Hour', description: 'Real-time data processing.', quantity: 0 },
        { id: '3018', name: 'Amazon Elasticsearch Service', price: 0.25, unit: 'Per Hour', description: 'Fully managed Elasticsearch service with built-in Kibana.', quantity: 0 },
        { id: '3019', name: 'AWS Direct Connect', price: 0.03, unit: 'Per GB', description: 'Establish dedicated network connections to AWS.', quantity: 0 },
        { id: '3020', name: 'Amazon EMR', price: 0.11, unit: 'Per Hour', description: 'Cloud big data platform to process vast amounts of data.', quantity: 0 },
        { id: '3021', name: 'AWS Glue', price: 0.44, unit: 'Per DPU-Hour', description: 'Managed ETL service for data preparation.', quantity: 0 },
        { id: '3022', name: 'Amazon Aurora', price: 0.10, unit: 'Per Hour', description: 'High-performance managed relational database service.', quantity: 0 },
        { id: '3023', name: 'Amazon Lex', price: 4.00, unit: 'Per 1,000 Speech Requests', description: 'Build conversational interfaces using voice and text.', quantity: 0 },
        { id: '3024', name: 'AWS CodeBuild', price: 0.005, unit: 'Per Build Minute', description: 'Fully managed continuous integration service.', quantity: 0 },
        { id: '3025', name: 'AWS CodeDeploy', price: 0, unit: 'Free', description: 'Automates code deployments to any instance.', quantity: 0 },
        { id: '3026', name: 'Amazon Polly', price: 4.00, unit: 'Per 1 Million Characters', description: 'Turn text into lifelike speech.', quantity: 0 },
        { id: '3027', name: 'Amazon Rekognition', price: 1.00, unit: 'Per 1,000 Images', description: 'Service for image and video analysis.', quantity: 0 },
        { id: '3028', name: 'Amazon SageMaker', price: 0.12, unit: 'Per Hour', description: 'Build, train, and deploy machine learning models at scale.', quantity: 0 },
        { id: '3029', name: 'Amazon MQ', price: 0.15, unit: 'Per Broker Hour', description: 'Managed message broker service for Apache ActiveMQ.', quantity: 0 },
        { id: '3030', name: 'Amazon Elastic Beanstalk', price: 0, unit: 'Free', description: 'Service for deploying and scaling web applications.', quantity: 0 },
        { id: '3031', name: 'AWS Fargate', price: 0.04048, unit: 'Per vCPU Hour', description: 'Serverless compute engine for containers.', quantity: 0 },
        { id: '3032', name: 'AWS WAF', price: 5.00, unit: 'Per Web ACL', description: 'Web application firewall to help protect your applications.', quantity: 0 },
        { id: '3033', name: 'Amazon EKS', price: 0.10, unit: 'Per Hour', description: 'Managed Kubernetes service for running and scaling containerized applications.', quantity: 0 },
        { id: '3034', name: 'AWS Secrets Manager', price: 0.40, unit: 'Per Secret', description: 'Manage access to secrets like database credentials.', quantity: 0 },
        { id: '3035', name: 'AWS Step Functions', price: 0.025, unit: 'Per 1,000 State Transitions', description: 'Serverless orchestration service.', quantity: 0 },
        { id: '3036', name: 'AWS AppSync', price: 4.00, unit: 'Per Million Queries', description: 'Fully managed service for building GraphQL APIs.', quantity: 0 },
        { id: '3037', name: 'Amazon WorkSpaces', price: 7.25, unit: 'Per Month', description: 'Fully managed desktop virtualization service.', quantity: 0 },
        { id: '3038', name: 'Amazon QuickSight', price: 18.00, unit: 'Per User Per Month', description: 'Business intelligence service with data visualizations.', quantity: 0 },
        { id: '3039', name: 'AWS Elastic Transcoder', price: 0.015, unit: 'Per Minute', description: 'Media transcoding service in the cloud.', quantity: 0 },
        { id: '3040', name: 'AWS App Mesh', price: 0.0066, unit: 'Per vCPU Hour', description: 'Service mesh to help monitor and control microservices.', quantity: 0 },
        { id: '3041', name: 'AWS CodePipeline', price: 1.00, unit: 'Per Active Pipeline Per Month', description: 'Automated release pipelines to deliver software quickly.', quantity: 0 },
        { id: '3042', name: 'Amazon Kinesis Video Streams', price: 0.0085, unit: 'Per Hour', description: 'Stream video from connected devices to AWS.', quantity: 0 },
        { id: '3043', name: 'Amazon Forecast', price: 0.60, unit: 'Per 1,000 Predictions', description: 'Time-series forecasting using machine learning.', quantity: 0 },
        { id: '3044', name: 'AWS IoT Core', price: 0.08, unit: 'Per Million Messages', description: 'Connect Internet of Things (IoT) devices to the cloud.', quantity: 0 },
        { id: '3045', name: 'AWS Backup', price: 0.01, unit: 'Per GB', description: 'Centralized backup service for AWS resources.', quantity: 0 },
        { id: '3046', name: 'Amazon FSx for Windows File Server', price: 0.13, unit: 'Per GB Per Month', description: 'Managed Windows file storage.', quantity: 0 },
        { id: '3047', name: 'Amazon WorkDocs', price: 5.00, unit: 'Per User Per Month', description: 'Fully managed, secure enterprise storage and sharing.', quantity: 0 },
        { id: '3048', name: 'AWS Global Accelerator', price: 18.00, unit: 'Per Month', description: 'Improve availability and performance of applications with global users.', quantity: 0 },
        { id: '3049', name: 'AWS Transfer Family', price: 0.30, unit: 'Per Hour', description: 'Fully managed support for SFTP, FTPS, and FTP.', quantity: 0 },
        { id: '3050', name: 'AWS Personal Health Dashboard', price: 0, unit: 'Free', description: 'Personalized view of AWS service health.', quantity: 0 },
      ];
      setServices(sampleServices);
    } catch (error) {
      logError(error as Error);
      setError('Failed to fetch AWS services. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (id: string, change: number) => {
    try {
      setSelectedServices(prevServices =>
        prevServices.map(service =>
          service.id === id ? { ...service, quantity: Math.max(0, service.quantity + change) } : service
        )
      );
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while updating the service quantity. Please try again.');
    }
  };

  const addService = (service: AWSService) => {
    try {
      if (!selectedServices.some(s => s.id === service.id)) {
        setSelectedServices([...selectedServices, { ...service, quantity: 1 }]);
      }
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while adding the service. Please try again.');
    }
  };

  const removeService = (id: string) => {
    try {
      setSelectedServices(selectedServices.filter(service => service.id !== id));
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while removing the service. Please try again.');
    }
  };

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="p-4 bg-white rounded-lg shadow-md">Loading AWS services...</div>;
  }

  if (error) {
    return <div className="p-4 bg-white rounded-lg shadow-md text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col bg-[#D5D2CA]">
      <div className="bg-[#002D5A] text-white py-4 px-6 rounded-t-lg">
        <h1 className="text-2xl font-bold">AWS Services Cost Calculator</h1>
      </div>
      <div className="bg-white p-6 rounded-b-lg shadow-md">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Service list */}
          <div className="lg:w-1/2">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search for an AWS service..."
                className="w-full p-2 border rounded bg-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="mb-4 max-h-96 overflow-y-auto bg-gray-50 rounded shadow">
              {filteredServices.map(service => (
                <div key={service.id} className="flex justify-between items-center p-3 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                     onClick={() => addService(service)}
                     onMouseEnter={() => setSelectedService(service)}
                     onMouseLeave={() => setSelectedService(null)}>
                  <span>{service.name} (${service.price.toFixed(4)}/{service.unit})</span>
                  <span className="text-sm text-gray-500">{service.id}</span>
                </div>
              ))}
            </div>

            {selectedService && (
              <div className="mb-4 p-3 bg-gray-50 rounded shadow">
                <h3 className="font-bold">{selectedService.name}</h3>
                <p>{selectedService.description}</p>
              </div>
            )}
          </div>

          {/* Selected services and total cost */}
          <div className="lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Selected AWS Services</h2>
            <div className="space-y-3">
              {selectedServices.map((service) => (
                <div key={service.id} className="flex items-center justify-between p-3 bg-gray-50 rounded shadow">
                  <div>
                    <span className="font-bold">{service.name}</span>
                    <br />
                    <span className="text-sm text-gray-500">{service.id}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2">${service.price.toFixed(4)}/{service.unit}</span>
                    <button onClick={() => handleQuantityChange(service.id, -1)} className="p-1 bg-gray-200 rounded"><Minus size={16} /></button>
                    <span className="mx-2">{service.quantity}</span>
                    <button onClick={() => handleQuantityChange(service.id, 1)} className="p-1 bg-gray-200 rounded"><Plus size={16} /></button>
                    <button onClick={() => removeService(service.id)} className="ml-2 p-1 bg-gray-200 rounded"><Trash2 size={16} /></button>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 text-xl font-bold">
              Total Cost (including 25% markup): ${totalCost.toFixed(2)}/month
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWSServicesCalculator;