import React from 'react';
import * as XLSX from 'xlsx';

interface Service {
  id: string;
  name: string;
  price: number;
  unit: string;
  description: string;
  quantity: number;  // Added quantity field
}

interface SelectedServicesTotalCostProps {
  awsTotal: number;
  azureTotal: number;
  iotTotal: number;
  selectedServices: Service[];
}

const SelectedServicesTotalCost: React.FC<SelectedServicesTotalCostProps> = ({ awsTotal, azureTotal, iotTotal, selectedServices }) => {
  const grandTotal = awsTotal + azureTotal + iotTotal;
  const totalCount = selectedServices.length;
  const awsCount = selectedServices.filter(s => s.id.startsWith('3')).length;
  const azureCount = selectedServices.filter(s => s.id.startsWith('2')).length;
  const iotCount = selectedServices.filter(s => s.id.startsWith('1')).length;

  const handleExcelExport = () => {
    // Prepare data for the services sheet
    const servicesData = selectedServices.map(service => ({
      ID: service.id,
      Name: service.name,
      Price: service.price,
      Unit: service.unit,
      Description: service.description,
      Category: service.id.startsWith('1') ? 'IoT' : (service.id.startsWith('2') ? 'Azure' : 'AWS'),
      "Number of Items": service.quantity  // Use the quantity field directly
    }));

    // Create the services worksheet
    const servicesWorksheet = XLSX.utils.json_to_sheet(servicesData);

    // Prepare data for the summary sheet
    const summaryData = [
      { Category: 'AWS', Cost: awsTotal, Count: awsCount },
      { Category: 'Azure', Cost: azureTotal, Count: azureCount },
      { Category: 'IoT', Cost: iotTotal, Count: iotCount },
      { Category: 'Grand Total', Cost: grandTotal, Count: totalCount }
    ];

    // Create the summary worksheet
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData);

    // Create a new workbook and append both worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, servicesWorksheet, "Selected Services");
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Cost Summary");

    // Auto-fit columns for both sheets
    const fitColumns = (worksheet: XLSX.WorkSheet) => {
      const objectMaxLength: { [key: string]: number } = {};
      for (const [, row] of Object.entries(worksheet)) {
        for (const [key, value] of Object.entries(row)) {
          const columnValue = value?.toString() || '';
          objectMaxLength[key] = Math.max(objectMaxLength[key] || 0, columnValue.length);
        }
      }
      worksheet["!cols"] = Object.keys(objectMaxLength).map(key => ({ wch: objectMaxLength[key] }));
    };

    fitColumns(servicesWorksheet);
    fitColumns(summaryWorksheet);

    // Write the workbook to a file
    XLSX.writeFile(workbook, "selected_services_summary.xlsx");
  };

  return (
    <div className="flex flex-col bg-[#D5D2CA] p-4">
      <div className="bg-[#002d5a] text-white py-4 px-6 rounded-t-lg">
        <h2 className="text-2xl font-bold">Selected Services Total Cost</h2>
      </div>
      <div className="bg-white p-4 rounded-b-lg shadow-md">
        <div className="space-y-2 mb-4">
          <p className="text-gray-700 font-semibold">AWS Total (including 25% markup): <span className="font-bold">${awsTotal.toFixed(2)}</span> ({awsCount} services)</p>
          <p className="text-gray-700 font-semibold">Azure Total (including 25% markup): <span className="font-bold">${azureTotal.toFixed(2)}</span> ({azureCount} services)</p>
          <p className="text-gray-700 font-semibold">IoT Total: <span className="font-bold">${iotTotal.toFixed(2)}</span> ({iotCount} services)</p>
        </div>
        <div className="border-t pt-4">
          <p className="text-gray-800 text-xl font-bold">Grand Total: ${grandTotal.toFixed(2)}/month</p>
          <p className="text-gray-600 text-sm mt-1">(Includes 25% markup on AWS and Azure services)</p>
          <p className="text-gray-600 text-sm mt-1">Total Selected Services: {totalCount}</p>
        </div>
        <div className="mt-4">
          <button
            onClick={handleExcelExport}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Export to Excel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectedServicesTotalCost;