import React, { useState, useEffect } from 'react';
import { Plus, Minus, Trash2 } from 'lucide-react';
import { logError } from '../utils/logger';

interface AzureService {
  id: string;
  name: string;
  price: number;
  unit: string;
  description: string;
  quantity: number;  // Added quantity field
}

interface AzureServicesCalculatorProps {
  onTotalChange: (total: number, services: AzureService[]) => void;
}

const AzureServicesCalculator: React.FC<AzureServicesCalculatorProps> = ({ onTotalChange }) => {
  const [services, setServices] = useState<AzureService[]>([]);
  const [selectedServices, setSelectedServices] = useState<AzureService[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [selectedService, setSelectedService] = useState<AzureService | null>(null);

  useEffect(() => {
    fetchAzureServices();
  }, []);

  useEffect(() => {
    try {
      // Calculate total cost with 25% markup (1.25 multiplier)
      const newTotalCost = selectedServices.reduce((sum, service) => sum + service.quantity * service.price * 1.25, 0);
      setTotalCost(newTotalCost);
      onTotalChange(newTotalCost, selectedServices);
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while calculating the total cost. Please try again.');
    }
  }, [selectedServices, onTotalChange]);

  const fetchAzureServices = async () => {
    setLoading(true);
    setError(null);

    try {
      // Simulating an API call with setTimeout
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Sample data to simulate Azure services
      const sampleServices: AzureService[] = [
        { id: '2001', name: 'Azure Virtual Machines', price: 70.00, unit: 'Per Month', description: 'Provision Windows and Linux virtual machines in seconds.', quantity: 0 },
        { id: '2002', name: 'Azure Blob Storage', price: 0.02, unit: 'Per GB', description: 'Massively scalable and secure object storage for cloud-native workloads.', quantity: 0 },
        { id: '2003', name: 'Azure SQL Database', price: 15.00, unit: 'Per Month', description: 'Managed relational SQL database as a service.', quantity: 0 },
        { id: '2004', name: 'Azure Kubernetes Service (AKS)', price: 0.10, unit: 'Per Hour', description: 'Manage and deploy Kubernetes clusters.', quantity: 0 },
        { id: '2005', name: 'Azure Cosmos DB', price: 23.00, unit: 'Per Month', description: 'Globally distributed, multi-model database.', quantity: 0 },
        { id: '2006', name: 'Azure Active Directory', price: 6.00, unit: 'Per Month Per User', description: 'Comprehensive identity and access management service.', quantity: 0 },
        { id: '2007', name: 'Azure Backup', price: 10.00, unit: 'Per Month', description: 'Simplified data protection for your data in the cloud.', quantity: 0 },
        { id: '2008', name: 'Azure DevOps', price: 30.00, unit: 'Per Month Per User', description: 'Services for teams to share code, track work, and ship software.', quantity: 0 },
        { id: '2009', name: 'Azure App Service', price: 50.00, unit: 'Per Month', description: 'Easily build and host web apps, mobile backends, and APIs in any programming language.', quantity: 0 },
        { id: '2010', name: 'Azure Monitor', price: 0.02, unit: 'Per GB', description: 'Full observability into your applications, infrastructure, and network.', quantity: 0 },
        { id: '2011', name: 'Azure Logic Apps', price: 0.002, unit: 'Per Execution', description: 'Automate workflows and integrate apps, data, and services.', quantity: 0 },
        { id: '2012', name: 'Azure VPN Gateway', price: 30.00, unit: 'Per Month', description: 'Secure, cross-premises connectivity.', quantity: 0 },
        { id: '2013', name: 'Azure CDN', price: 0.09, unit: 'Per GB', description: 'Deliver high-bandwidth content globally.', quantity: 0 },
        { id: '2014', name: 'Azure DNS', price: 0.50, unit: 'Per Zone Per Month', description: 'Host your DNS domains in Azure.', quantity: 0 },
        { id: '2015', name: 'Azure Site Recovery', price: 25.00, unit: 'Per Month', description: 'Keep your business running with built-in disaster recovery service.', quantity: 0 },
        { id: '2016', name: 'Azure Cognitive Services', price: 1.00, unit: 'Per 1,000 Transactions', description: 'AI and machine learning APIs for vision, speech, language, and decision making.', quantity: 0 },
        { id: '2017', name: 'Azure Load Balancer', price: 0.02, unit: 'Per Hour', description: 'Scale your applications with high availability.', quantity: 0 },
        { id: '2018', name: 'Azure Virtual Network', price: 0.01, unit: 'Per Hour', description: 'Provision private networks, connect clouds securely.', quantity: 0 },
        { id: '2019', name: 'Azure Data Lake Storage', price: 0.05, unit: 'Per GB', description: 'High-performance, massively scalable data lake solution.', quantity: 0 },
        { id: '2020', name: 'Azure Firewall', price: 1.25, unit: 'Per Hour', description: 'Managed, cloud-based network security service.', quantity: 0 },
        { id: '2021', name: 'Azure ExpressRoute', price: 55.00, unit: 'Per Month', description: 'Private connections to Azure for performance and security.', quantity: 0 },
        { id: '2022', name: 'Azure HDInsight', price: 0.30, unit: 'Per Hour', description: 'Big data and analytics cloud service using popular open-source frameworks.', quantity: 0 },
        { id: '2023', name: 'Azure Service Bus', price: 10.00, unit: 'Per Month', description: 'Reliable cloud messaging service between applications and services.', quantity: 0 },
        { id: '2024', name: 'Azure Event Grid', price: 0.60, unit: 'Per 1,000,000 Operations', description: 'Event routing service.', quantity: 0 },
        { id: '2025', name: 'Azure Event Hubs', price: 0.50, unit: 'Per 1,000,000 Messages', description: 'Big data streaming platform and event ingestion service.', quantity: 0 },
        { id: '2026', name: 'Azure Functions', price: 0.20, unit: 'Per 1,000,000 Executions', description: 'Serverless compute service that lets you run event-triggered code.', quantity: 0 },
        { id: '2027', name: 'Azure Bot Service', price: 0.50, unit: 'Per Session', description: 'Develop intelligent, enterprise-grade bots.', quantity: 0 },
        { id: '2028', name: 'Azure Machine Learning', price: 1.00, unit: 'Per Hour', description: 'Cloud-based environment for training, deploying, and managing machine learning models.', quantity: 0 },
        { id: '2029', name: 'Azure Automation', price: 0.02, unit: 'Per Job', description: 'Automate frequent, time-consuming, and error-prone management tasks.', quantity: 0 },
        { id: '2030', name: 'Azure Media Services', price: 0.15, unit: 'Per GB', description: 'End-to-end media distribution solutions.', quantity: 0 },
        { id: '2031', name: 'Azure Information Protection', price: 2.00, unit: 'Per Month Per User', description: 'Cloud-based solution that helps organizations protect their documents and emails.', quantity: 0 },
        { id: '2032', name: 'Azure Cache for Redis', price: 0.25, unit: 'Per Hour', description: 'Secure, dedicated Redis cache to power fast, scalable applications.', quantity: 0 },
        { id: '2033', name: 'Azure Mobile Apps', price: 10.00, unit: 'Per Month', description: 'Cloud backend for mobile app development.', quantity: 0 },
        { id: '2034', name: 'Azure Digital Twins', price: 0.10, unit: 'Per 1,000 Messages', description: 'IoT service that helps create comprehensive models of the physical environment.', quantity: 0 },
        { id: '2035', name: 'Azure Spatial Anchors', price: 0.07, unit: 'Per 1,000 Anchors', description: 'Developer service that provides a cross-platform, cloud-based spatial anchor solution.', quantity: 0 },
        { id: '2036', name: 'Azure Blockchain Service', price: 0.30, unit: 'Per Hour', description: 'Blockchain as a Service offering to build, govern, and expand blockchain networks.', quantity: 0 },
        { id: '2037', name: 'Azure Databricks', price: 0.40, unit: 'Per Hour', description: 'Fast, easy, and collaborative Apache Spark-based analytics platform.', quantity: 0 },
        { id: '2038', name: 'Azure Batch', price: 0.05, unit: 'Per Core Hour', description: 'Cloud-scale job scheduling and compute management.', quantity: 0 },
        { id: '2039', name: 'Azure Policy', price: 0.01, unit: 'Per Evaluation', description: 'Ensure compliance across your Azure resources.', quantity: 0 },
        { id: '2040', name: 'Azure Lighthouse', price: 0, unit: 'Free', description: 'Manage Azure services across different accounts securely.', quantity: 0 },
        { id: '2041', name: 'Azure Advisor', price: 0, unit: 'Free', description: 'Personalized recommendations to optimize Azure deployments.', quantity: 0 },
        { id: '2042', name: 'Azure Arc', price: 0.15, unit: 'Per Hour', description: 'Extend Azure management and services anywhere.', quantity: 0 },
        { id: '2043', name: 'Azure Sentinel', price: 2.00, unit: 'Per GB', description: 'Scalable, cloud-native SIEM and SOAR solution.', quantity: 0 },
        { id: '2044', name: 'Azure Security Center', price: 15.00, unit: 'Per Month', description: 'Unified infrastructure security management system.', quantity: 0 },
        { id: '2045', name: 'Azure IoT Central', price: 0.50, unit: 'Per Device', description: 'IoT app platform that simplifies the creation of IoT solutions.', quantity: 0 },
        { id: '2046', name: 'Azure Maps', price: 0.10, unit: 'Per 1,000 Transactions', description: 'Simple and secure location APIs provide geospatial context to data.', quantity: 0 },
        { id: '2047', name: 'Azure Quantum', price: 10.00, unit: 'Per Hour', description: 'Pioneering quantum computing and optimization solutions.', quantity: 0 },
        { id: '2048', name: 'Azure Virtual WAN', price: 1.00, unit: 'Per Hour', description: 'Unified wide area network solution to connect branches to Azure and to each other.', quantity: 0 },
        { id: '2049', name: 'Azure Firewall Manager', price: 0.10, unit: 'Per Policy', description: 'Central security policy management and routing service for your cloud-based network.', quantity: 0 },
        { id: '2050', name: 'Azure Bastion', price: 0.19, unit: 'Per Hour', description: 'Fully managed platform service to provide secure and seamless RDP and SSH access to VMs.', quantity: 0 },
      ];
      setServices(sampleServices);
    } catch (error) {
      logError(error as Error);
      setError('Failed to fetch Azure services. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (id: string, change: number) => {
    try {
      setSelectedServices(prevServices =>
        prevServices.map(service =>
          service.id === id ? { ...service, quantity: Math.max(0, service.quantity + change) } : service
        )
      );
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while updating the service quantity. Please try again.');
    }
  };

  const addService = (service: AzureService) => {
    try {
      if (!selectedServices.some(s => s.id === service.id)) {
        setSelectedServices([...selectedServices, { ...service, quantity: 1 }]);
      }
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while adding the service. Please try again.');
    }
  };

  const removeService = (id: string) => {
    try {
      setSelectedServices(selectedServices.filter(service => service.id !== id));
    } catch (error) {
      logError(error as Error);
      setError('An error occurred while removing the service. Please try again.');
    }
  };

  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="p-4 bg-white rounded-lg shadow-md">Loading Azure services...</div>;
  }

  if (error) {
    return <div className="p-4 bg-white rounded-lg shadow-md text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col bg-[#D5D2CA]">
      <div className="bg-[#002d5a] text-white py-4 px-6 rounded-t-lg">
        <h1 className="text-2xl font-bold">Azure Services Cost Calculator</h1>
      </div>
      <div className="bg-white p-6 rounded-b-lg shadow-md">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Service list */}
          <div className="lg:w-1/2">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search for an Azure service..."
                className="w-full p-2 border rounded bg-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="mb-4 max-h-96 overflow-y-auto bg-gray-50 rounded shadow">
              {filteredServices.map(service => (
                <div key={service.id} className="flex flex-col p-3 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                     onClick={() => addService(service)}
                     onMouseEnter={() => setSelectedService(service)}
                     onMouseLeave={() => setSelectedService(null)}>
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">{service.name}</span>
                    <span className="text-sm text-gray-500">{service.id}</span>
                  </div>
                  <div className="text-sm text-gray-600 mt-1">${service.price.toFixed(4)}/{service.unit}</div>
                </div>
              ))}
            </div>

            {selectedService && (
              <div className="mb-4 p-3 bg-gray-50 rounded shadow">
                <h3 className="font-bold">{selectedService.name}</h3>
                <p className="text-sm mt-1">{selectedService.description}</p>
              </div>
            )}
          </div>

          {/* Selected services and total cost */}
          <div className="lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Selected Azure Services</h2>
            <div className="space-y-3 max-h-96 overflow-y-auto">
              {selectedServices.map((service) => (
                <div key={service.id} className="flex items-center justify-between p-3 bg-gray-50 rounded shadow">
                  <div>
                    <span className="font-bold">{service.name}</span>
                    <br />
                    <span className="text-sm text-gray-500">{service.id}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2">${service.price.toFixed(4)}/{service.unit}</span>
                    <button onClick={() => handleQuantityChange(service.id, -1)} className="p-1 bg-gray-200 rounded"><Minus size={16} /></button>
                    <span className="mx-2">{service.quantity}</span>
                    <button onClick={() => handleQuantityChange(service.id, 1)} className="p-1 bg-gray-200 rounded"><Plus size={16} /></button>
                    <button onClick={() => removeService(service.id)} className="ml-2 p-1 bg-gray-200 rounded"><Trash2 size={16} /></button>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 text-xl font-bold">
              Total Cost (including 25% markup): ${totalCost.toFixed(2)}/month
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AzureServicesCalculator;